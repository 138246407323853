import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import ModalImage from "react-modal-image";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import {
  bannerDeleteController,
  bannerUploadController,
} from "../../controller/BannerController";
import { posterListController } from "../../controller/PosterController";
import { postImages } from "../../controller/imageController";

const BannerDetail = () => {
  const { data: posterList } = useQuery({
    queryKey: ["poster_list"],
    queryFn: () => posterListController(0, 50, "ACTIVE"),
  });

  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const bannerData = JSON.parse(queryParams.get("data"));

  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState("");

  const [checkedId, setCheckedId] = React.useState(1);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    const response = await postImages(formData);

    if (response.code === 1900) {
      setImageUrl(response.result.imageUrls[0]);
    } else {
      alert("사진 업로드에 실패했습니다.");
    }
  };

  React.useEffect(() => {
    if (bannerData) {
      setCheckedId(bannerData.posterId);
      setTitle(bannerData.title);
      setContent(bannerData.content);
      setImageUrl(bannerData.imageUrl);
    }
  }, []);

  return (
    <Container>
      <Header />
      <Box
        mb={5}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight={"700"}>
          배너 상세 / 수정 / 등록 페이지
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
          }}
        >
          <Button
            variant="contained"
            onClick={async () => {
              console.log(id);
              console.log(title, content, imageUrl, checkedId);
              if (checkedId) {
                if (id) {
                  const response = await bannerUploadController(
                    {
                      title: title,
                      content: content,
                      imageUrl: imageUrl,
                      posterId: checkedId,
                    },
                    id
                  );

                  if (response.code === 7008) {
                    navigate(-1);
                  }
                } else {
                  const response = await bannerUploadController({
                    title: title,
                    content: content,
                    imageUrl: imageUrl,
                    posterId: checkedId,
                  });
                  if (response.code === 7006) {
                    navigate(-1);
                  }
                }
              }
            }}
          >
            등록 / 수정
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              bannerDeleteController(id);
              navigate(-1);
            }}
          >
            삭제
          </Button>
        </Box>
      </Box>
      <TextField
        variant="outlined"
        sx={{
          width: "100%",
          marginBottom: 1.5,
        }}
        InputLabelProps={{}}
        value={title}
        label="제목을 입력하세요"
        onChange={(e) => setTitle(e.target.value)}
      />

      <TextField
        variant="outlined"
        sx={{
          width: "100%",
          marginBottom: 1.5,
        }}
        InputLabelProps={{}}
        value={content}
        label="내용을 입력하세요"
        multiline={true}
        onChange={(e) => setContent(e.target.value)}
      />
      <Typography variant="body1">이미지 선택</Typography>
      <input
        accept="image/*"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="upload-button"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label htmlFor="upload-button">
          <Button
            variant="contained"
            component="span"
            sx={{
              marginBottom: 2,
            }}
          >
            이미지 선택
          </Button>
        </label>
        {imageUrl !== "" && (
          <ModalImage
            className="modal-banner-style"
            small={imageUrl}
            large={imageUrl}
            hideDownload={false}
            hideZoom={false}
          />
        )}
      </Box>
      <Grid
        container
        py={1.5}
        px={1}
        sx={{
          backgroundColor: "silver",
        }}
      >
        <Grid item xl={1} md={1}>
          선택
        </Grid>
        <Grid item xl={4} md={4}>
          이벤트 이름
        </Grid>
        <Grid item xl={2} md={2}>
          장르
        </Grid>
        <Grid item xl={5} md={5}>
          이벤트 날짜
        </Grid>
      </Grid>
      {posterList !== undefined &&
        posterList.posters.length > 0 &&
        posterList?.posters.map((posterEl, index) => (
          <Grid
            container
            py={1.5}
            px={1}
            key={`${JSON.stringify(posterEl.posterId)}_${index}`}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid silver",
              "&: hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() => {
              setCheckedId(posterEl.posterId);
            }}
          >
            <Grid
              item
              xl={1}
              md={1}
              sx={{
                diplay: "flex",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "15px",
                  height: "15px",
                  border:
                    checkedId === posterEl.posterId
                      ? "2px solid black"
                      : "1px solid silver",
                  backgroundColor:
                    checkedId === posterEl.posterId ? "red" : "#fff",
                }}
              />
            </Grid>
            <Grid item xl={4} md={4}>
              {posterEl.posterId}/{posterEl.eventName}
            </Grid>
            <Grid item xl={2} md={2}>
              {posterEl.genre}
            </Grid>
            <Grid item xl={5} md={5}>
              {posterEl.startDate}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
};

export default BannerDetail;
