import axios from "axios";
import { getToken } from "../utils/localStorage";

export const getUserList = async (page = 0, size = 100) => {
  const endPoint = `/api/admins?page=${page}&size=${size}`;

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response.data.result.users;
  } catch (error) {
    throw error;
  }
};

export const deleteUserController = async (id) => {
  const endPoint = `/api/admins/members/deactivate/${id}`;
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    alert(response.data.message);
    return response.data;
  } catch (error) {
    throw error;
  }
};
